import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { makeHomeState } from './selectors';
import { getHome } from './actions';
import homeOffice from '../../images/homeOfficeBlk.jpg';

const styles = theme => ({
  tagLineStyle: {
    fontSize: '1.5em',
    fontStyle: 'italic',
    fontWeight: '250',
    letterSpacing: '1px',
    lineHeight: '1em',
    color: '#fff'
  },
  tagLine2Style: {
    marginTop: '.50em',
    fontSize: '4.5em',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '2.5em'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em'
    },
    fontWeight: '700',
    letterSpacing: '1px',
    lineHeight: '1em',
    color: '#fff',
    textTransform: 'upperCase'
  },
  tagLineContainerStyle: {
    position: 'relative',
    top: '50%'
  },
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // justifyContent: 'space-around',
    // overflow: 'hidden',
    backgroundImage: `url(${homeOffice} )`,
    width: '100%',
    height: '60vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
    // backgroundColor: ,
  },
  rootOverlay: {
    background: 'linear-gradient(rgba(0,0,0,0.40), rgba(0,0,0,0.40))',
    width: '100%',
    height: '60vh'
  },
  gridList: {
    width: 1300,
    height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  icon: {
    color: 'white'
  },

  imageWidthStyle: {
    width: '100%'
  }
});

class HomePage extends React.Component {
  componentDidMount() {
    this.props.getHome();
  }

  render() {
    const {
      home: { tagLine, tagLine2 },
      classes
    } = this.props;

    return (
      <div>
        <section className={classes.root}>
          <div className={classes.rootOverlay}>
            <div className={classes.tagLineContainerStyle}>
              <Typography align='center' className={classes.tagLineStyle}>
                {tagLine}
              </Typography>
              <Typography align='center' className={classes.tagLine2Style}>
                {tagLine2}{' '}
              </Typography>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  home: makeHomeState()
});

const mapDispatchToProps = dispatch => {
  return {
    getHome: () => dispatch(getHome())
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(HomePage);
