import React from 'react';
import Typography from '@material-ui/core/Typography';

const Contact = () => {
  return (
    <div>
      <Typography align="center" variant="h4">
        Contact
      </Typography>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus quo
        vero suscipit assumenda impedit dolor commodi laborum, cupiditate
        inventore possimus sapiente placeat est itaque atque incidunt quia
        praesentium provident necessitatibus!
      </p>
    </div>
  );
};

export default Contact;
