import React from 'react';

import NavBar from '../Navbar';

function Header() {
  return (
    <div>
      <NavBar />
    </div>
  );
}

export default Header;
