import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import lightGreen from '@material-ui/core/colors/lightGreen';
import grey from '@material-ui/core/colors/grey';

const fontFamilySans = '"Source Sans Pro", "Helvetica", "Arial", sans-serif';

const theme = createMuiTheme({
  direction: 'ltr',
  typography: {
    useNextVariants: true
  },
  breakpoints: {
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 }
  },

  palette: {
    primary: {
      light: grey[700],
      main: grey[800],
      dark: grey[900]
    },
    secondary: {
      light: lightGreen[400],
      main: lightGreen[500],
      dark: lightGreen[600]
    },
    neutralColor: {
      light: grey[700],
      main: grey[800],
      dark: grey[900]
    },
    error: {
      main: red.A400
    }
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: fontFamilySans
      },
      display1: {
        fontFamily: fontFamilySans
      },
      display2: {
        fontFamily: fontFamilySans
      },
      display3: {
        fontFamily: fontFamilySans
      },
      display4: {
        fontFamily: fontFamilySans
      },
      title: {
        fontFamily: fontFamilySans,
        fontSize: '1.1rem',
        fontWeight: 600,
        color: grey[800]
      },
      subheading: {
        fontFamily: fontFamilySans
      },
      body2: {
        fontFamily: fontFamilySans
      },
      body1: {
        fontFamily: fontFamilySans
      },
      caption: {
        fontFamily: fontFamilySans
      },
      button: {
        fontFamily: fontFamilySans
      },
      paragraph: {
        fontFamily: fontFamilySans
      },
      colorPrimary: {
        color: grey[800]
      },
      colorSecondary: {
        color: lightGreen[500]
      },
      colorError: {
        color: red.A400
      }
    },
    MuiSelect: {
      root: {
        marginLeft: 20,
        fontFamily: fontFamilySans,
        fontWeight: 700,
        fontSize: 14,
        minWidth: 47
      }
    },
    MuiButton: {
      outlined: {
        border: '1px solid #fff',
        // backgroundColor: '#d5d5d5',
        color: '#fff'
      }
    }
  }
});

export default theme;
