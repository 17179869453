export const GET_HOME = 'GET_HOME';
export const SET_HOME = 'SET_HOME';

export function getHome() {
  return {
    type: GET_HOME
  };
}
export function setHome(home) {
  return {
    type: SET_HOME,
    home: home
  };
}
