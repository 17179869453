import { fromJS } from 'immutable';

import { SET_RESUME, GET_RESUME, SET_RESUME_LOCAL_STORAGE } from './actions';
import Log from '../../utils/Log';

export const initialState = fromJS({
  resume: {
    tagLine: null,
    tagLine2: null,
    skills: {
      languages: [],
      databases: [],
      tools: [],
      frameworks: [],
      operationSystems: [],
      methodologies: []
    },
    workExperience: [],
    education: [],
    loading: false
  },
  loading: false,
  resumePrint: false
});

function ResumePageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESUME:
      return state.setIn(['resume', 'loading'], true);
    case SET_RESUME:
      Log.info('SET_RESUME: loading set to false', 'ResumePage reducer');

      return state
        .merge({
          resume: {
            tagLine: fromJS(action.resume.tagLine),
            tagLine2: fromJS(action.resume.tagLine2),
            skills: {
              languages: fromJS(action.resume.skills.languages),
              databases: fromJS(action.resume.skills.databases),
              frameworks: fromJS(action.resume.skills.frameworks),
              buildTools: fromJS(action.resume.skills.buildTools),
              operatingSystems: fromJS(action.resume.skills.operatingSystems),
              methodologies: fromJS(action.resume.skills.methodologies)
            },

            workExperience: fromJS(action.resume.workExperience),
            education: fromJS(action.resume.education)
          }
        })
        .setIn(['resume', 'loading'], false);
    case SET_RESUME_LOCAL_STORAGE:
      return state
        .merge({
          resume: {
            tagLine: fromJS(action.resume.tagLine),
            tagLine2: fromJS(action.resume.tagLine2),
            skills: {
              languages: fromJS(action.resume.skills.languages),
              databases: fromJS(action.resume.skills.databases),
              frameworks: fromJS(action.resume.skills.frameworks),
              buildTools: fromJS(action.resume.skills.buildTools),
              operatingSystems: fromJS(action.resume.skills.operatingSystems),
              methodologies: fromJS(action.resume.skills.methodologies)
            },

            workExperience: fromJS(action.resume.workExperience),
            education: fromJS(action.resume.education)
          }
        })
        .setIn(['resume', 'loading'], false);
    default:
      return state;
  }
}

export default ResumePageReducer;
