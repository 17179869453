import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';

import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';

import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';
import logo from '../images/ds-light.png';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  appBar: {
    position: 'relative'
  },
  toolbarTitle: {
    flex: 1
  },
  imagePointer: {
    cursor: 'pointer'
  }
});

const messages = defineMessages({
  home: {
    id: 'nav.home',
    defaultMessage: '[Home]'
  },
  about: {
    id: 'nav.about',
    defaultMessage: '[About]'
  },
  contact: {
    id: 'nav.contact',
    defaultMessage: '[Contact]'
  },
  resume: {
    id: 'nav.resume',
    defaultMessage: '[Resume]'
  }
});
// TODO: move this to a separate file?
export const tabs = fromJS([
  {
    id: 'home',
    value: '/'
  },
  {
    id: 'about',
    value: '/about'
  },
  {
    id: 'resume',
    value: '/resume'
  },
  {
    id: 'contact',
    value: '/contact'
  }
]);

export class NavBar extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      appBar: PropTypes.string.isRequired,
      toolbarTitle: PropTypes.string.isRequired
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired
  };

  validateValue() {
    const { location } = this.props;
    const { pathname } = location;
    return tabs.some(tab => tab.get('value') === pathname) ? pathname : false;
  }

  generateTabList() {
    const generatedTabList = tabs.map(tab => (
      <Tab
        component={Link}
        key={tab.get('id')}
        label={<FormattedMessage {...messages[tab.get('id')]} />}
        to={tab.get('value')}
        value={tab.get('value')}
      />
    ));

    return generatedTabList;
  }

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  render() {
    const { classes, intl } = this.props;
    const appName = intl.formatMessage({ id: 'app.name' });
    return (
      <div>
        <AppBar position='static' className={classes.appBar}>
          <Toolbar>
            <Typography
              variant='h6'
              color='inherit'
              noWrap
              className={classes.toolbarTitle}
            >
              <img
                className={classes.imagePointer}
                src={logo}
                alt={appName}
                onClick={this.handleLogoClick}
              />
            </Typography>
            <Tabs value={this.validateValue()} variant='fullWidth'>
              {this.generateTabList()}
            </Tabs>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  injectIntl
)(NavBar);
