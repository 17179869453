import { createSelector } from 'reselect';

// selector
const getResume = state => state.get('resumePage');
const getResumePrint = state => state.get('resumePage');
// reselect function
export const makeResumeState = () =>
  createSelector(
    getResume,
    state => state.get('resume')
  );

export const makeResumePrintState = () =>
  createSelector(
    getResumePrint,
    state => state.get('resumePrint')
  );
