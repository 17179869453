import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';

import HomePage from '../../containers/HomePage';
import ResumePage from '../../containers/ResumePage';
import About from '../../components/About';
import Contact from '../../components/Contact';
import MetaPage from '../../components/MetaPage';

export const Public = () => (
  <h3>
    <FormattedMessage
      id={'routes.public'}
      defaultMessage={'[Public - No Login Required]'}
    />
  </h3>
);
export const Protected = () => (
  <h3>
    <FormattedMessage
      id={'routes.protected'}
      defaultMessage={'[Protected - Login Required]'}
    />
  </h3>
);

export default class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path={'/about'} component={About} />

        <Route path={'/contact'} component={Contact} />

        <Route path={'/resume'} component={ResumePage} />

        <Route path={'/meta'} component={MetaPage} />
        <Route exact path={'/'} component={HomePage} />
      </Switch>
    );
  }
}
