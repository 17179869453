import { call, put, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

import { GET_RESUME, SET_RESUME, SET_RESUME_LOCAL_STORAGE } from './actions';
import resumeTestData from '../../utils/resumeTestData.json';

const {
  REACT_APP_RESUME_API_BASE_URL: resumeBaseUrl,
  REACT_APP_BYPASS_SERVER: bypassServer
} = process.env;

function fetchResume() {
  return axios.request({
    method: 'get',
    url: resumeBaseUrl
  });
}

const delay = ms => new Promise(res => setTimeout(res, ms));

const fetchResumeTestData = async () => {
  // await delay(5000);

  return resumeTestData;
};

function* fetchResumeEffectSaga(action) {
  if (bypassServer !== 'BYPASS') {
    try {
      let response = yield call(fetchResume);

      const val = {
        summary: 'BlaBlaBla and that all folks...',
        skills: {
          languages: response.data,

          databases: [{ id: 1, name: 'PostgeSQL' }],
          frameworks: [{ id: 1, name: 'Spring' }],
          buildTools: [{ id: 1, name: 'Maven' }],
          operatingSystems: [{ id: 1, name: 'MacOS' }],
          methodologies: [{ id: 1, name: 'Agile' }]
        },
        education: [
          { id: 1, name: 'Bachelors Degree in Information Technology' },
          { id: 2, name: 'Associates Degree in Business Administration' }
        ],
        workExperience: [
          {
            id: 1,
            name: 'NAVIS',
            city: 'Bend',
            state: 'OR',
            startDate: '01/15',
            endDate: null,
            title: 'Sr. Software Engineer',
            description:
              'NAVIS is the 1st complete customer relationship management software, specifically designed for the hospitality industry'
          }
        ]
      };

      yield put({
        type: SET_RESUME,
        resume: val
      });
    } catch (error) {
      console.log('something went wrong loading from local storage-- ', error);
    }
  } else {
    const val = yield fetchResumeTestData();

    yield put({
      type: SET_RESUME,
      resume: val
    });
  }
}

export function* watchFetchResume() {
  yield takeLatest(GET_RESUME, fetchResumeEffectSaga);
}

export const resumePageSagas = [watchFetchResume()];
