import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FormattedMessage, FormattedDate } from 'react-intl';

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';

import { makeResumeState, makeResumePrintState } from './selectors';
import { getResume } from './actions';
import RingLoader from 'react-spinners/RingLoader';
import homeOffice from '../../images/homeOfficeBlk.jpg';

const styles = theme => ({
  tagLineStyle: {
    fontSize: '24px',
    fontStyle: 'italic',
    fontWeight: '250',
    letterSpacing: '1px',
    lineHeight: '1em',
    color: '#fff'
  },
  tagLine2Style: {
    marginTop: '15px',
    fontSize: '4.5em',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '2.5em'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em'
    },
    fontWeight: '700',
    letterSpacing: '1px',
    lineHeight: '1em',
    color: '#fff',
    textTransform: 'upperCase'
  },
  tagLineContainerStyle: {
    position: 'relative',
    top: '40%'
  },
  gridRoot: {
    flexGrow: 1,
    padding: '30px 60px'
  },
  root: {
    backgroundImage: `url(${homeOffice} )`,
    width: '100%',
    height: '60vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  rootOverlay: {
    background: 'linear-gradient(rgba(0,0,0,0.40), rgba(0,0,0,0.40))',
    width: '100%',
    height: '60vh'
  },
  gridList: {
    width: 1300,
    height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  icon: {
    color: 'white'
  },

  imageWidthStyle: {
    width: '100%'
  },

  workExperienceTitleStyle: {
    fontWeight: '600'
  },
  educationTitleStyle: {
    fontWeight: '600'
  },
  button: {
    color: '#fff',
    justifyContent: 'center'
  },

  downloadResumeStyle: {
    position: 'relative',
    justifyContent: 'center',

    top: '50%',
    left: '40%',
    [theme.breakpoints.between('md', 'sm')]: {
      left: '35%'
    },
    [theme.breakpoints.down('sm')]: {
      left: '20%'
    }
  },
  sectionStyle: {
    paddingRight: '.75em'
  }
});

class ResumePage extends React.Component {
  constructor() {
    super();
    this.state = {
      download: false
    };
  }
  componentDidMount() {
    this.props.getResume();
  }

  buildEducationList = education => {
    const { classes } = this.props;

    const educationList = education.size
      ? education.map(e => {
          return (
            <div key={e.get('id')}>
              <Typography
                variant='subtitle1'
                className={classes.educationTitleStyle}
              >
                {e.get('name')}
              </Typography>

              <br />
            </div>
          );
        })
      : 'No Work Experience';

    return educationList;
  };
  buildWorkExperienceList = workExperience => {
    const { classes } = this.props;
    var sorted = workExperience.size
      ? workExperience.sortBy(w => w.get('id'))
      : [];

    const workExperienceList = sorted.size
      ? sorted.map(w => {
          return (
            <div key={w.get('id')}>
              <Typography
                variant='subtitle1'
                className={classes.workExperienceTitleStyle}
              >
                {w.get('title')}
              </Typography>
              <Typography variant='subtitle1'>
                {this.buildWorkExperienceTitle(w)}
              </Typography>
              <Typography>{w.get('description')}</Typography>
              <br />
            </div>
          );
        })
      : 'No Work Experience';

    return workExperienceList;
  };

  buildWorkExperienceTitle = w => {
    const { classes } = this.props;
    const title = w.get('endDate') ? (
      <div className={classes.workExperienceTitleStyle}>
        {' '}
        {w.get('name')}
        {', '}
        {w.get('city')}
        {', '}
        {w.get('state')}
        {', '}
        <FormattedDate
          value={w.get('startDate')}
          year='numeric'
          month='short'
        />
        {' - '}
        <FormattedDate value={w.get('endDate')} year='numeric' month='short' />
      </div>
    ) : (
      <div className={classes.workExperienceTitleStyle}>
        {' '}
        {w.get('name')}
        {', '}
        {w.get('city')}
        {', '}
        {w.get('state')}
        {', '}
        <FormattedDate
          value={w.get('startDate')}
          year='numeric'
          month='short'
        />
        {' - '}
        <FormattedMessage
          id={'resumePage.present'}
          defaultMessage={'[Present]'}
        />
      </div>
    );

    return title;
  };

  buildSkillLevelBarChart = skillData => {
    return (
      <BarChart
        layout='vertical'
        margin={{ top: 5, right: 30, left: 50, bottom: 5 }}
        width={500}
        height={300}
        data={JSON.parse(JSON.stringify(skillData))}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis type='number' />
        <YAxis type='category' dataKey='name' />
        <Tooltip />
        <Bar dataKey='skillLevel' fill='#8bc34a' />
      </BarChart>
    );
  };
  buildSkillLevelTitle = (id, defaultMessage) => {
    return (
      <Typography align='center' variant='h6'>
        <FormattedMessage id={id} defaultMessage={defaultMessage} />{' '}
      </Typography>
    );
  };

  render() {
    const {
      resume: { tagLine, tagLine2, skills, workExperience, education },
      classes
    } = this.props;

    let resumePanel;

    resumePanel = (
      <div>
        <section>
          <div className={classes.gridRoot}>
            <Grid container spacing={24}>
              <div>
                <Grid item xs>
                  {this.buildSkillLevelTitle(
                    'resumePage.databases',
                    '[Databases]'
                  )}
                </Grid>

                <Grid item xs>
                  {skills && skills.databases && skills.databases.size ? (
                    this.buildSkillLevelBarChart(skills.databases)
                  ) : (
                    <RingLoader loading={true} />
                  )}
                </Grid>
              </div>

              <div>
                <Grid item xs>
                  {this.buildSkillLevelTitle(
                    'resumePage.languages',
                    '[Languages]'
                  )}
                </Grid>
                <Grid item xs>
                  {skills && skills.languages && skills.languages.size ? (
                    this.buildSkillLevelBarChart(skills.languages)
                  ) : (
                    <RingLoader loading={true} />
                  )}
                </Grid>
              </div>
            </Grid>
          </div>
        </section>

        <section>
          <div className={classes.gridRoot}>
            <Grid container spacing={24}>
              <div>
                <Grid item xs>
                  {this.buildSkillLevelTitle(
                    'resumePage.frameworks',
                    '[Frameworks]'
                  )}
                </Grid>
                <Grid item xs>
                  {skills && skills.frameworks && skills.frameworks.size ? (
                    this.buildSkillLevelBarChart(skills.frameworks)
                  ) : (
                    <RingLoader loading={true} />
                  )}
                </Grid>
              </div>
              <div>
                <Grid item xs>
                  {this.buildSkillLevelTitle(
                    'resumePage.buildTools',
                    '[BuildTools]'
                  )}
                </Grid>
                <Grid item xs>
                  {skills && skills.buildTools && skills.buildTools.size ? (
                    this.buildSkillLevelBarChart(skills.buildTools)
                  ) : (
                    <RingLoader loading={true} />
                  )}
                </Grid>
              </div>
            </Grid>
          </div>
        </section>

        <section>
          <div className={classes.gridRoot}>
            <Grid container spacing={24}>
              <div>
                <Grid item xs>
                  {this.buildSkillLevelTitle(
                    'resumePage.methodologies',
                    '[Methodology]'
                  )}
                </Grid>

                <Grid item xs>
                  {skills &&
                  skills.methodologies &&
                  skills.methodologies.size ? (
                    this.buildSkillLevelBarChart(skills.methodologies)
                  ) : (
                    <RingLoader loading={true} />
                  )}
                </Grid>
              </div>

              <div>
                <Grid item xs>
                  {this.buildSkillLevelTitle(
                    'resumePage.operatingSystems',
                    '[Operating Systems]'
                  )}
                </Grid>
                <Grid item xs>
                  {skills &&
                  skills.operatingSystems &&
                  skills.operatingSystems.size ? (
                    this.buildSkillLevelBarChart(skills.operatingSystems)
                  ) : (
                    <RingLoader loading={true} />
                  )}
                </Grid>
              </div>
            </Grid>
          </div>
        </section>

        <section>
          <div className={classes.gridRoot}>
            <Grid container spacing={24}>
              <div className={classes.sectionStyle}>
                <Grid item xs>
                  <Typography align='center' variant='h6'>
                    <FormattedMessage
                      id={'resumePage.workExperience'}
                      defaultMessage={'[Work Experience]'}
                    />{' '}
                  </Typography>
                </Grid>
              </div>
              <div>
                <Grid item xs>
                  {workExperience && workExperience.size ? (
                    this.buildWorkExperienceList(workExperience)
                  ) : (
                    <RingLoader loading={true} />
                  )}
                </Grid>
              </div>
            </Grid>
          </div>
        </section>
        <section>
          <div className={classes.gridRoot}>
            <Grid container spacing={24}>
              <div className={classes.sectionStyle}>
                <Grid item xs>
                  <Typography align='center' variant='h6'>
                    <FormattedMessage
                      id={'resumePage.education'}
                      defaultMessage={'[Education]'}
                    />{' '}
                  </Typography>
                </Grid>{' '}
              </div>
              <div>
                <Grid item xs>
                  {education && education.size ? (
                    this.buildEducationList(education)
                  ) : (
                    <RingLoader loading={true} />
                  )}
                </Grid>
              </div>
            </Grid>
          </div>
        </section>
      </div>
    );

    return (
      <div>
        <section className={classes.root}>
          <div className={classes.rootOverlay}>
            <div className={classes.tagLineContainerStyle}>
              <Typography align='center' className={classes.tagLineStyle}>
                {tagLine}
              </Typography>
              <Typography align='center' className={classes.tagLine2Style}>
                {tagLine2}
              </Typography>
            </div>
            <div className={classes.downloadResumeStyle}>
              <Button
                variant='outlined'
                className={classes.button}
                href='/myPDF.pdf'
                target='_blank'
              >
                <Typography
                  align='center'
                  variant='h6'
                  className={classes.button}
                >
                  <FormattedMessage
                    id={'resumePage.downloadResume'}
                    defaultMessage={'[DownLoad Resume]'}
                  />{' '}
                </Typography>
              </Button>
            </div>
          </div>
        </section>

        <section>{resumePanel}</section>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  resume: makeResumeState(),
  resumePrint: makeResumePrintState()
});

const mapDispatchToProps = dispatch => {
  return {
    getResume: () => dispatch(getResume())
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ResumePage);
