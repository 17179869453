import { createSelector } from 'reselect';

// selector
const getHome = state => state.get('homePage');

// reselect function
export const makeHomeState = () =>
  createSelector(
    getHome,
    state => state.get('home')
  );
