import { call, put, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

import { GET_HOME, SET_HOME } from './actions';
import homeTestData from '../../utils/homeTestData.json';

const {
  REACT_APP_RESUME_API_BASE_URL: homeBaseUrl,
  REACT_APP_BYPASS_SERVER: bypassServer
} = process.env;

function fetchHome() {
  return axios.request({
    method: 'get',
    url: homeBaseUrl
  });
}
const fetchHomeTestData = async () => {
  return homeTestData;
};
function* fetchHomeEffectSaga(action) {
  if (bypassServer !== 'BYPASS') {
    try {
      let response = yield call(fetchHome);

      const val = null;

      yield put({
        type: SET_HOME,
        resume: val
      });
    } catch (error) {
      console.log('something went wrong -- ', error);
    }
  } else {
    const val = yield fetchHomeTestData();

    yield put({
      type: SET_HOME,
      home: val
    });
  }
}

export function* watchFetchHome() {
  yield takeLatest(GET_HOME, fetchHomeEffectSaga);
}

export const homePageSagas = [watchFetchHome()];
