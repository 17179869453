import { combineReducers } from 'redux-immutable';

import languageReducer from './components/LanguageSelector/reducer';
import homePageReducer from './containers//HomePage/reducer';
import resumePageReducer from './containers//ResumePage/reducer';

const reducers = combineReducers({
  i18n: languageReducer,
  homePage: homePageReducer,
  resumePage: resumePageReducer
});

export default reducers;
