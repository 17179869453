import { fromJS } from 'immutable';

import { SET_HOME, GET_HOME } from './actions';
import Log from '../../utils/Log';

export const initialState = fromJS({
  home: {
    tagLine: null,
    tagLine2: null
  },
  loading: false
});

function homePageReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HOME:
      return state.setIn(['home', 'loading'], true);
    case SET_HOME:
      Log.info('GET_SAGA_TEST: loading set to false', 'SagaExample reducer');

      return state
        .merge({
          home: {
            tagLine: fromJS(action.home.tagLine),
            tagLine2: fromJS(action.home.tagLine2)
          }
        })
        .setIn(['home', 'loading'], false);
    default:
      return state;
  }
}

export default homePageReducer;
