import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import './index.css';
import I18n from './containers/I18n';
import * as serviceWorker from './serviceWorker';
import store from './store';
import ErrorBoundary from './utils/ErrorBoundry';

ReactGA.initialize('UA-137822718-1');

const {
  REACT_APP_ENABLE_DEBUG_LOGGING: enableDebugLogging,
  REACT_APP_ID: appId
} = process.env;

if (enableDebugLogging === 'true') {
  localStorage.setItem('debug', `${appId}:*`);
}

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <I18n />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
