export const GET_RESUME = 'GET_RESUME';
export const SET_RESUME = 'SET_RESUME';
export const SET_RESUME_LOCAL_STORAGE = 'SET_RESUME_LOCAL_STORAGE';

export function getResume() {
  return {
    type: GET_RESUME
  };
}
export function setResume(resume) {
  return {
    type: SET_RESUME,
    resume: resume
  };
}

export function setResumeLocalStorage() {
  return {
    type: SET_RESUME_LOCAL_STORAGE
  };
}
